.deletePostModal {
    position: relative;
}
.deletePostModal .title {
    font-weight: bold;
    font-size: 18px;
}
.deletePostModal input {
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid black;
    font-family: Roboto;
}
.deletePostModal textarea {
    border-radius: 10px;
    width: 100%;
    min-height: 100px;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid black;
    font-family: Roboto;
}
.deletePostModal .buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
@font-face {
  font-family: Roboto;
  src: url(../assets/fonts/Roboto/Roboto-Regular.ttf);
}
@font-face {
  font-family: Roboto;
  src: url(../assets/fonts/Roboto/Roboto-Bold.ttf);
  font-weight: bold;
}
@font-face {
  font-family: Roboto;
  src: url(../assets/fonts/Roboto/Roboto-Italic.ttf);
  font-style: italic;
}

html,
body {
  font-family: Roboto;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
body * {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
.landingPage {
  position: relative;
  width: 100%;
  height: 100%;
}

.standardPage {
  position: relative;
  max-width: 960px;
  margin: 0 auto;
}

.splashImage {
  position: relative;
  width: 100%;
  height: 30vh;
  background-image: url("../assets/images/players_huddle_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 0px -50px 40px rgba(0,0,0,0.3) inset;
}
.splashImage .splashText {
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 46px;
  color: #fce08a;
  font-weight: bold;
}
.pageContent {
  position: relative;
  max-width: 960px;
  margin: 0 auto;
  margin-top: 15px;
}
.pageContent .mainSection {
  background-color: #fff;
  padding: 15px;
  border-radius: 6px;
}
.pageContent.column {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.pageContent .card {
    background-color: #fff;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0px 0px 4px rgba(0,0,0,0.3);
    color: #000;
}
.leftSection {
  position: relative;
  width: 650px;
  min-width: 650px;
  margin-right: 15px;
  background-color: #fff;
  padding: 15px;
  border-radius: 6px;
}
.rightSection {
  width: 295px;
  background-color: #fff;
  padding: 15px;
  border-radius: 6px;
}
.rightSection .rightSectionItem {
  background-color:#fff;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.3);
  color: #000;
}
.rightSection .rightSectionItem:not(:first-child) {
  margin-top: 15px;
}
.rightSection .rightSectionItem .itemTitle {
  font-weight: bold;
  font-size: 20px;
}
.rightSection .trainingVideo {
  width: 100%;
}
.rightSection .trainingVideo iframe {
  display: block;
  border-radius: 6px;
  margin: 0 auto;
  margin-top: 10px;
}
.rightSection .socialMediaRow {
  display: flex;
  margin-top: 10px;
}
.rightSection .socialMediaRow .socialMediaIcon {
  width: 20px;
  height: 20px;
  background-color: #fff;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-right: 5px;
  border-radius: 4px;
}
.rightSection .socialMediaRow .socialMediaIcon.instagram {
  background-image: url("../assets/images/instagram_icon.png");
}
.rightSection .socialMediaRow .socialMediaIcon.facebook {
  background-image: url("../assets/images/facebook_icon.png");
}
.rightSection .socialMediaRow .socialMediaText {
  font-size: 16px;
  text-decoration: underline;
}
.rightSection .sponsorBanner {
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  margin-top: 10px;
  border-radius: 6px;
}
.contentHeader {
  font-size: 36px;
  font-weight: bold;
  color: #fce08a;
  background-color: #0b6d88;
  padding: 10px;
  border-radius: 6px;
}
.newsPosts {
  padding-top: 10px;
  padding-bottom: 10px;
}

.mainWrapper {
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}
.mainContainer {
  position: relative;
  background-color: #eceff1;
  overflow: scroll;
  height: 100%;
  height: 100%;
}
.container {
  background-color: #eceff1;
  margin: 0 auto;
}

.instagramPosts {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  max-width: 960px;
  margin: 0 auto;
}

@media screen and (max-width: 700px) {
  .splashImage .splashText {
    font-size: 40px;
    right: 10px;
  }

  .standardPage {
    width: 100%;
  }
  .pageContent {
    width: 100%;
  }
  .pageContent.column {
    flex-flow: wrap;
  }
  .leftSection,
  .rightSection {
    width: 100%;
    min-width: 100%;
  }
}
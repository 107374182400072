.instagramPost {
    position: relative;
    width: 310px;
    height: 310px;
    background-color: #fff;
    border-radius: 6px;
    margin-top: 15px;
}

.instagramPost video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.instagramPost .imagePost {
    width: 310px;
    min-width: 310px;
    height: 260px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.instagramPost .caption {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
}

.instagramPost .instaLogo {
    width: 20px;
    height: 20px;
    background-color: #fff;
    background-image: url("../assets/images/instagram_icon.png");
    background-size: 100%;
    background-repeat: no-repeat;
    margin-right: 5px;
    border-radius: 4px;
}

.instagramPost .text {
    font-weight: bold;
}
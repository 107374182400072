.scheduleContainer {
    position: relative;
    background-color: #fff;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 30%);
    color: #000;
    margin: 0 auto;
    flex-grow: 1;
    white-space: pre-wrap;
    margin-bottom: 15px;
}
.scheduleContainer .scheduleItemTitle {
    font-size: 25px;
    font-weight: bold;
}

.scheduleContainer .scheduleItemSubtitle {
    font-size: 20px;
    font-weight: bold;
    margin-top: 5px;
}
.scheduleContainer .scheduleItemContent {
    margin-top: 5px;
}
.scheduleContainer .deleteButton {
    position: absolute;
    top: -10px;
    left: -10px;
    width: 25px;
    height: 25px;
    background-color: red;
    background: radial-gradient(red, rgb(254, 99, 99));
    box-shadow: 0px 0px 5px #000;
    padding: 5px;
    border-radius: 50%;
    -webkit-user-select: none;
    user-select: none;
    text-align: center;
    color: #fff;
    line-height: 13px;
    border: 1px solid #fff;
    cursor: pointer;
}
.scheduleContainer .deleteButton:active {
    box-shadow: none;
    transform: translateY(1px);
    filter: brightness(75%);
}
.scheduleContainer .actionButtonsContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
}
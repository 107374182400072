.modalOverlay {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,0.4);
    z-index: 10;
}
.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #fff;
    box-shadow: 0px 0px 10px #000;
    padding: 10px;
    border-radius: 10px;
}
.modal .closeButton {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 25px;
    height: 25px;
    background-color: #8b4513;
    background: radial-gradient(#8b4513, #947157);
    box-shadow: 0px 0px 5px #000;
    padding: 5px;
    border-radius: 50%;
    user-select: none;
    text-align: center;
    color: #fff;
    z-index: 20;
    line-height: 13px;
    border: 1px solid #fff;
    cursor: pointer;
}
.modal .closeButton:active {
    box-shadow: none;
    transform: translateY(1px);
    filter: brightness(75%);
}

@media screen and (max-width: 600px) {
    .modalOverlay {

    }

    .modal {
        height: 90vh;
        width: 90vw;
    }
}
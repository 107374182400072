.coachSplashImage {
    width: 768px;
    height: 512px;
    background-image: url("../assets/images/coaches_carlos.jpeg");
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    margin-top: 15px;
    border-radius: 6px;
}
@media screen and (max-width: 768px) {
    .coachSplashImage {
        width: 100%;
        height: 300px;
    }
}
.content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-top: 15px;
    background-color: #fff;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 30%);
}
.content img {
    display: block;
    max-width: 300px;
    max-height: 200px;
    width: auto;
    height: auto;
    margin: 10px;
}
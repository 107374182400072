.infoContainer {
    display: flex;
}
.infoContainer .left {
    width: 300px;
    min-width: 300px;
    height: 574.5px;
    background-image: url("../assets/images/carlos.jpeg");
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 6px;
    margin-right: 15px;
    box-shadow: 5px 0px 10px rgba(0,0,0,0.3);
}
.largeHeader {
    font-size: 30px;
    font-weight: bold;
    margin-top: 25px;
}
.textSection {
    margin-top: 10px;
}
.mediumHeader {
    font-size: 25px;
    font-weight: bold;
    margin-top: 25px;
}

@media screen and (max-width: 700px) {
    .infoContainer {
        flex-wrap: wrap-reverse;
    }
    .infoContainer .left {
        background-size: cover;
        height: 350px;
        width: 100%;
        box-shadow: 0px 5px 10px rgba(0,0,0,0.3);
    }
}
.navBg {
    background-color: #0b6d88;
    /* background: linear-gradient(#0eb3e0 1%, #074f63); */
}
.nav {
    position: relative;
    max-width: 960px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.nav ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    margin: 0px;
    padding: 0px;
}
.nav ul .logo {
    width: 50px;
    height: 66.67px;
    background-image: url("../assets/images/caq_logo.png");
    background-size: 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}
.nav ul li {
    height: 66.67px;
    line-height: 66.67px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: transparent;
    cursor: pointer;
    user-select: none;
    color: #fce08a;
    font-size: 20px;
    font-weight: bold;
    /* text-shadow: 0px 0px 2px rgba(0,0,0,0.3); */
}
.nav ul li:hover {
    background-color: rgba(255,255,255,0.2);
}
.nav ul li:active {
    background-color: rgba(0,0,0,0.2);
}
.nav .loginButton {
    cursor: pointer;
    user-select: none;
    color: #fff;
    color: #fce08a;
    font-size: 16px;
    font-weight: bold;
}
.nav .actionButtonsContainer {
    display: flex;
    align-items: center;
}
.nav .hamburgerButton {
    display: none;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}
.nav .hamburgerButton .bar {
    width: 35px;
    height: 5px;
    background-color: #fce08a;
    margin: 6px 0;
}

@media screen and (max-width: 700px) {
    .nav .hamburgerButton {
        display: block;
    }
    .nav ul {
        flex-wrap: wrap;
        width: 100%;
    }
    .nav ul a:not(:first-child) {
        display: none;
        width: 100%;
    }
    .nav .actionButtonsContainer {
        position: absolute;
        top: 14px;
        right: 2px;
    }
    .nav.mobileMenuActive ul a {
        display: block;
    }
}

.pageTitle {
    font-size: 20px;
    font-weight: bold;
}
input {
    display: block;
    border-radius: 10px;
    width: 250px;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid black;
    font-family: Roboto;

}
.loginContainer {
    margin-top: 10px;
}
.loginContainer .loginButton {
    margin-top: 10px;
    background-color: lightblue;
    width: 100px;
    padding: 10px;
    text-align: center;
}
.loginContainer .loginButton:hover {
    filter: brightness(120%);
}
.loginContainer .loginButton:active {
    filter: brightness(75%);
}

@media screen and (max-width: 700px) {
    input {
        width: 100%;
    }
}
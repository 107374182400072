.infoContainer {
    display: flex;
}
.infoContainer .left {
    width: 300px;
    min-width: 300px;
    height: 574.5px;
    background-image: url("../assets/images/carlos.jpeg");
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 6px;
    margin-right: 15px;
    box-shadow: 5px 0px 10px rgba(0,0,0,0.3);
}
.largeHeader {
    font-size: 30px;
    font-weight: bold;
    margin-top: 25px;
}
.textSection {
    margin-top: 10px;
}
.mediumHeader {
    font-size: 25px;
    font-weight: bold;
    margin-top: 25px;
}
.addNewScheduleContainer {
    border: 1px dotted #000;
    padding: 15px;
    border-radius: 6px;
    background-color: #eee;
}
.addNewScheduleContainer input {
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid black;
    font-family: Roboto;
}

.addNewScheduleContainer textarea {
    border-radius: 10px;
    width: 100%;
    min-height: 200px;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid black;
    font-family: Roboto;
}
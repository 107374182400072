.addPostModal {
    position: relative;
}
.addPostModal .title {
    font-weight: bold;
    font-size: 18px;
}
.addPostModal input {
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid black;
    font-family: Roboto;
}
.addPostModal textarea {
    border-radius: 10px;
    width: 100%;
    min-height: 200px;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid black;
    font-family: Roboto;
}

.addPostModal .uploadedImage {
    width: 100%;
    height: 0px;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: lightgray;
    background-position: center;
    margin: 0 auto;
    border-radius: 10px;
}
.addPostModal .uploadedImage.active {
    height: 200px;
    margin-top: 10px;
}
.addPostModal .imageSelectorContainer {
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.addPostModal .imageSelector {
    width: 85%;
    margin-right: 5px;
    margin-top: 0px;
}
.addPostModal .crudButton {
    position: relative;
    width: 100%;
}
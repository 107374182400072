.downloadsContainer {
    
}
.downloadItem {
    background-color: #0eb3e0;
    padding: 5px;
    border-radius: 6px;
    text-align: center;
    max-width: 300px;
    color: #fff;
    font-weight: bold;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
    border: 1px solid #000;
    margin-top: 15px;
    cursor: pointer;
}
.downloadItem:hover {
    filter: brightness(110%);
}
.downloadItem:active {
    filter: brightness(80%);
    box-shadow: 0px 0px 1px rgba(0,0,0,0.3);
}
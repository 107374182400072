.crudButton {
    width: 90px;
    min-width: 90px;
    padding: 5px;
    padding-left: 7px;
    padding-right: 7px;
    background-color: gray;
    text-align: center;
    color: #000;
    cursor: pointer;
    user-select: none;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
    border-radius: 4px;
    border: 1px solid #000;
}
.crudButton.green {
    background-color: #66bb6a;
    background-image: linear-gradient(#81c784, #66bb6a);
    border-color: #2e7d32;
}
.crudButton.red {
    background-color: #ef5350;
    background-image: linear-gradient(#e57373, #ef5350);
    border-color: #c62828;
}
.crudButton.gold {
    background-color: #ffee58;
    background-image: linear-gradient(#fff176, #ffee58);
    border-color: #f9a825;
}
.crudButton.grey {
    background-color: #bdbdbd;
    background-image: linear-gradient(#e0e0e0, #bdbdbd);
    border-color: #424242;
}
.crudButton:active {
    box-shadow: none;
    filter: brightness(75%);
}